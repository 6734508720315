/* Section --------------------------------------- */
section {
  position: fixed;
  top: 10%;
  left: 0;
  height: 80%;
  width: 100%;
  color: #111;
  pointer-events: none;
  background-color: #7c7c7c;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 600ms;
}
section.show {
  pointer-events: auto;
  opacity: 1;
}
section.front {
  z-index: 2;
}

#photos {
  width: auto;
  min-width: 100%;
  padding-top: 5px;
  background-color: black;
}

.abstract .leaflet-marker-icon.abstract,
.abstract .leaflet-marker-shadow.abstract,
.animal .leaflet-marker-icon.animal,
.animal .leaflet-marker-shadow.animal,
.bone .leaflet-marker-icon.bone,
.bone .leaflet-marker-shadow.bone,
.creature .leaflet-marker-icon.creature,
.creature .leaflet-marker-shadow.creature,
.face .leaflet-marker-icon.face,
.face .leaflet-marker-shadow.face,
.nature .leaflet-marker-icon.nature,
.nature .leaflet-marker-shadow.nature,
.throw-up .leaflet-marker-icon.throw-up,
.throw-up .leaflet-marker-shadow.throw-up,
.vehicle .leaflet-marker-icon.vehicle,
.vehicle .leaflet-marker-shadow.vehicle,
.angled .leaflet-marker-icon.angled,
.angled .leaflet-marker-shadow.angled,
.colorful .leaflet-marker-icon.colorful,
.colorful .leaflet-marker-shadow.colorful,
.monochrome .leaflet-marker-icon.monochrome,
.monochrome .leaflet-marker-shadow.monochrome,
.realistic .leaflet-marker-icon.realistic,
.realistic .leaflet-marker-shadow.realistic,
.stencil .leaflet-marker-icon.stencil,
.stencil .leaflet-marker-shadow.stencil {
  display: block;
}

#about,
#other {
  overflow-y: auto;
  padding: 5vmin;
  background: #7c7c7c url("/images/bg.png") repeat;
}
@media (min-width: 600px) {
  #about,
#other {
    padding: 7.5vmin;
  }
}

.external-links {
  margin: 2.5rem 0;
}
.external-links a {
  color: #ddd;
  letter-spacing: 0.02em;
  text-decoration: none;
  border-bottom: 1px dashed transparent;
  transition: color 100ms, border-bottom 100ms;
}
.external-links a:focus, .external-links a:hover {
  color: #a1c7ff;
  border-bottom: 1px dashed #a1c7ff;
}

@media (min-width: 420px) {
  .cols-2 {
    display: flex;
  }
  .cols-2 > .col {
    width: 45%;
  }
  .cols-2 > .col:first-child {
    margin-right: 10%;
  }
}