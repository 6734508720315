/* Icons ----------------------------------------- */

#icons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    min-width: 5rem;
    margin-bottom: -0.35rem;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 900ms;

    &.show {
        opacity: 1;
    }
}

.icon {
    flex: 1;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0 1.25rem;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    perspective: 4.5rem;
    transform-style: preserve-3d;
    transition: transform 600ms;
    will-change: transform;

    &.flip {
        transform: rotateY(180deg);
    }
}

#toggle-icon {
    background-image: url('/images/icons/grid.svg');

    &.map-icon {
        background-image: url('/images/icons/map.svg');
    }
}

#menu-icon {
    background-image: url('/images/icons/menu.svg');
}
