/* Header ---------------------------------------- */

@import '_hf';

header {
    @extend %hf;
    align-items: flex-end;
    top: 0;
    color: gold;
    font-size: 2rem;
    border-bottom: 5px solid gold;

    &.open {
        transform: translateY(-80%);
    }

    > span {
        display: inline-block;
    }
}

#title {
    margin-bottom: -0.35rem;
    font-family: 'Capture It', sans-serif;
    letter-spacing: 0.1em;
}
