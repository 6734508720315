/* Miscellaneous --------------------------------- */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  font: normal 16px sans-serif;
  line-height: 1;
  background-color: black;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.25rem;
  margin: 2rem 0 1rem;
}

p {
  margin-bottom: 0.8rem;
  line-height: 1.4;
  letter-spacing: 0.01em;
}

/* #shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    pointer-events: none;
    opacity: 0;
    transition: opacity 900ms;
    will-change: opacity;

    &.show {
        opacity: 0.5;
    }
} */