/* Menu ------------------------------------------ */

#menu {
    position: fixed;
    right: 0;
    top: 10vh;
    z-index: 8;
    height: 80vh;
    width: 11rem;
    padding: 10px;
    overflow-y: auto;
    text-align: right;
    background-image: linear-gradient(to right, rgba(black, 0) 0, rgba(black, 0.9) 100%);
    transform: translateX(11rem);
    transition: transform 600ms;
    will-change: transform;

    &.show {
        transform: translateX(0);
    }

    > ul {
        margin-bottom: 15px;
    }

    li {
        padding: 5px;
        list-style-type: none;
    }
}

.menu-title {
    margin-bottom: 5px;
    color: #a1c7ff;
    font-family: 'Capture It', sans-serif;
    letter-spacing: 0.1em;
}

.filter {
    color: #ccc;
    transition: color 100ms;
    cursor: pointer;

    &::after {
        content: '✘';  /* ✗ */
        display: inline-block;
        width: 1rem;
        margin-left: 0.15rem;
        text-align: center;
    }

    &:focus,
    &:hover {
        color: #a1c7ff;
    }

    &.selected {
        color: gold;

        &::after {
            content: '✔';
        }
    }
}
