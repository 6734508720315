/* Footer ---------------------------------------- */

@import '_hf';

footer {
    @extend %hf;
    align-items: center;
    bottom: 0;
    letter-spacing: 0.02em;
    border-top: 5px solid gold;

    &.open {
        transform: translateY(80%);
    }

    > ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: calc(80% + 5px);
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        max-width: 840px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 900ms;

        &.show {
            pointer-events: auto;
            opacity: 1;
        }
    }

    li {
        display: inline-block;
        list-style-type: none;
        color: #ddd;
        border-bottom: 1px dashed transparent;
        transition: color 100ms, border-bottom 100ms;
    }

    .exif-value {
        color: #a1c7ff;
        white-space: nowrap;
    }
}

#nav > li {
    padding: 0.1rem 0;
    cursor: pointer;

    &:focus,
    &:hover {
        color: #a1c7ff;
        border-bottom: 1px dashed #a1c7ff;
    }
}

#exif {
    @media (max-height: 480px) {
        font-size: 0.8em;
    }
}

#exif > li {
    margin-top: 0.2rem;
    padding: 0 1rem;
    line-height: 1.4;

    @media (min-width: 768px) {
        margin-top: 0;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
        display: none;

        @media (min-width: 460px) {
            display: inline-block;
        }
    }

    &:last-of-type {
        @media (min-width: 460px) {
            display: none;
        }

        @media(min-width: 520px) {
            display: inline-block;
        }
    }
}
