/* Footer ---------------------------------------- */
/* Partial for Header & Footer ------------------- */
footer {
  display: flex;
  justify-content: space-around;
  position: fixed;
  left: 0;
  z-index: 9;
  width: 100%;
  height: calc(50% + 2.5px);
  background-color: black;
  transition: transform 900ms;
  will-change: transform;
}

footer {
  align-items: center;
  bottom: 0;
  letter-spacing: 0.02em;
  border-top: 5px solid gold;
}
footer.open {
  transform: translateY(80%);
}
footer > ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: calc(80% + 5px);
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  max-width: 840px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 900ms;
}
footer > ul.show {
  pointer-events: auto;
  opacity: 1;
}
footer li {
  display: inline-block;
  list-style-type: none;
  color: #ddd;
  border-bottom: 1px dashed transparent;
  transition: color 100ms, border-bottom 100ms;
}
footer .exif-value {
  color: #a1c7ff;
  white-space: nowrap;
}

#nav > li {
  padding: 0.1rem 0;
  cursor: pointer;
}
#nav > li:focus, #nav > li:hover {
  color: #a1c7ff;
  border-bottom: 1px dashed #a1c7ff;
}

@media (max-height: 480px) {
  #exif {
    font-size: 0.8em;
  }
}

#exif > li {
  margin-top: 0.2rem;
  padding: 0 1rem;
  line-height: 1.4;
}
@media (min-width: 768px) {
  #exif > li {
    margin-top: 0;
  }
}
#exif > li:nth-of-type(3), #exif > li:nth-of-type(4) {
  display: none;
}
@media (min-width: 460px) {
  #exif > li:nth-of-type(3), #exif > li:nth-of-type(4) {
    display: inline-block;
  }
}
@media (min-width: 460px) {
  #exif > li:last-of-type {
    display: none;
  }
}
@media (min-width: 520px) {
  #exif > li:last-of-type {
    display: inline-block;
  }
}