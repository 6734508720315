@charset "UTF-8";
/* Menu ------------------------------------------ */
#menu {
  position: fixed;
  right: 0;
  top: 10vh;
  z-index: 8;
  height: 80vh;
  width: 11rem;
  padding: 10px;
  overflow-y: auto;
  text-align: right;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.9) 100%);
  transform: translateX(11rem);
  transition: transform 600ms;
  will-change: transform;
}
#menu.show {
  transform: translateX(0);
}
#menu > ul {
  margin-bottom: 15px;
}
#menu li {
  padding: 5px;
  list-style-type: none;
}

.menu-title {
  margin-bottom: 5px;
  color: #a1c7ff;
  font-family: "Capture It", sans-serif;
  letter-spacing: 0.1em;
}

.filter {
  color: #ccc;
  transition: color 100ms;
  cursor: pointer;
}
.filter::after {
  content: "✘";
  /* ✗ */
  display: inline-block;
  width: 1rem;
  margin-left: 0.15rem;
  text-align: center;
}
.filter:focus, .filter:hover {
  color: #a1c7ff;
}
.filter.selected {
  color: gold;
}
.filter.selected::after {
  content: "✔";
}