/* Fonts ------------------------------------------ */
@font-face {
  font-family: "Capture It";
  src: url("/fonts/Capture_It.woff2") format("woff2"), url("/fonts/Capture_It.woff") format("woff"), url("/fonts/Capture_It.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
    font-family: 'HVD Peace';
    src: url('fonts/HVD_Peace.woff2') format('woff2'),
         url('fonts/HVD_Peace.woff') format('woff'),
         url('fonts/HVD_Peace.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} */