/* Photo ----------------------------------------- */
/* All .photo widths based on vh are overridden by JavaScript. See link below for explanation.
   https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html */
.photo {
  height: calc(25% - 6.25px);
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 100ms;
}
.photo:focus, .photo:hover, .photo.selected {
  box-shadow: 0 0 0 2px gold;
}
.photo.x2 {
  height: calc((25% - 8px) * 2 + 8px);
}
.photo.landscape {
  width: calc((20vh - 6.25px) * 1.3333333333333);
}
.photo.landscape.x2 {
  width: calc(((20vh - 8px) * 1.3333333333333) * 2 + (8px * 1.3333333333333));
}
.photo.portrait {
  width: calc((20vh - 6.25px) * 0.75);
}
.photo.portrait.x2 {
  width: calc(((20vh - 8px) * 0.75) * 2 + (8px * 0.75));
}
.photo.front {
  z-index: 2;
  transition: box-shadow 100ms, transform 900ms;
}
.photo.selected {
  z-index: 3;
}
.photo > img {
  width: 100%;
  height: 100%;
  text-indent: -100%;
  pointer-events: none;
}
.photo > img.overlay {
  position: absolute;
  top: 0;
  left: 0;
}
.photo > .photo-info {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-size: 0.8rem;
  padding: 0.2rem;
  color: gold;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.666) 0, rgba(0, 0, 0, 0) 100%);
  border-radius: 0;
  border: 0;
  opacity: 0;
  transition: opacity 100ms;
}
.photo > .photo-info:focus, .photo > .photo-info.show {
  opacity: 1;
}

.map-photo.landscape {
  height: calc(20vh - 6.25px);
  width: calc((20vh - 6.25px) * 1.3333333333333);
}
.map-photo.portrait {
  height: calc((20vh - 6.25px) * 1.3333333333333);
  width: calc(20vh - 6.25px);
}
.map-photo > img {
  width: 100%;
  height: 100%;
}

#photo-20161029_133BTM,
#map-photo-20161029_133BTM {
  width: calc(20vh * 3.4933333) !important;
}