/* Partial for Header & Footer ------------------- */

%hf {
    display: flex;
    justify-content: space-around;
    position: fixed;
    left: 0;
    z-index: 9;
    width: 100%;
    height: calc(50% + 2.5px);
    background-color: black;
    transition: transform 900ms;
    will-change: transform;
}
