/* Header ---------------------------------------- */
/* Partial for Header & Footer ------------------- */
header {
  display: flex;
  justify-content: space-around;
  position: fixed;
  left: 0;
  z-index: 9;
  width: 100%;
  height: calc(50% + 2.5px);
  background-color: black;
  transition: transform 900ms;
  will-change: transform;
}

header {
  align-items: flex-end;
  top: 0;
  color: gold;
  font-size: 2rem;
  border-bottom: 5px solid gold;
}
header.open {
  transform: translateY(-80%);
}
header > span {
  display: inline-block;
}

#title {
  margin-bottom: -0.35rem;
  font-family: "Capture It", sans-serif;
  letter-spacing: 0.1em;
}